// Libs
import React, { PureComponent } from 'react';
// import PropTypes from 'prop-types';

import { homeToolbarIcon } from 'data/config/toolbarConfig';
import './HomeToolbar.scss';

class HomeToolbar extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      homeToolbarIcon: homeToolbarIcon && homeToolbarIcon() ? homeToolbarIcon() : [],
    };
  }

  render() {
    const { homeToolbarIcon } = this.state;
    return (
      <div id="home-toolbar">
        {homeToolbarIcon && homeToolbarIcon.length !== 0 && homeToolbarIcon.map((button,index) => (
          <div key={index} className="ht-button" onClick={button.action} title={button.label}>
            {typeof button.badge === 'number' && (
              <span className="ht-button-badge">{button.badge}</span>
            )}
            {button.filePath ? (
              button.filePath.includes("data:image/svg+xml") ? (
                <div dangerouslySetInnerHTML={{ __html: decodeURI(button.filePath.replace("data:image/svg+xml,", "")) }}/>
                ) : (
                <img src={button.filePath} title={button.label} alt={button.alt} />
            ) ) : (
              <span
                style={button.style}
                title={button.label}
                className={button.className + ' ' + button.isFontAwesome || ''}
              >
                {button.isFontProject || ''}
              </span>
            )}
          </div>
        ))}
      </div>
    );
  }
}

/* HomeToolbar.propTypes = {
  labels: PropTypes.object.isRequired,
  associatedPageKey: PropTypes.string.isRequired,
  actions: PropTypes.object.isRequired,
  isLoggedIn: PropTypes.bool,
  inboxCount: PropTypes.number,
}; */

export default HomeToolbar;
